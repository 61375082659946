/* eslint-disable no-redeclare */
import React, { Component } from "react";
import axios from "axios";
import { notifyError, notifySuccess } from "../data/toats";

class LanguageForm extends Component {
  state = {
    name: "",
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  saveLanguage = async (e) => {
    e.preventDefault();
    const res = await axios.post(
      `${process.env.REACT_APP_URL}/api/add-language`,
      this.state
    );
    if (res.data.status === 200) {
      console.log(res.data.message);
      this.setState({
        name: "",
      });
      notifySuccess();
    }

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 422) {
          notifyError("Le langage n'a pas été ajouté");
          console.log("Le langage n'a pas été ajouté", error.response.data);
        }
        return Promise.reject(error);
      }
    );
  };
  render() {
    return (
      <div className="rounded-sm border border-grisBtn bg-blanc shadow-default p-5 h-fit">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-noir">Formulaire de langage</h3>
        </div>

        <form onSubmit={this.saveLanguage}>
          <div className="w-full mt-2">
            <label htmlFor="name" className="mb-2.5 block text-noir">
              Nom du langage
            </label>
            <input
              type="text"
              name="name"
              id="name"
              className="w-full rounded border-[1.5px] border-stroke border-grisBtn bg-transparent py-3 px-5 text-noir outline-none transition focus:border-bleu active:border-bleu disabled:cursor-default disabled:bg-blanc"
              onChange={this.handleInput}
              value={this.state.name}
              required
            />

            <button
              type="submit"
              className="select-none rounded-lg bg-bleu mt-2 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-blanc shadow-md shadow-bleu/20 transition-all hover:shadow-lg hover:shadow-bleu/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

class LanguageList extends Component {
  state = {
    languages: [],
    loading: true,
  };

  async componentDidMount() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_URL}/api/languages`);
      //console.log("requete axios langages :", res);
      if (res.data.status === 200) {
        this.setState({
          languages: res.data.languages,
          loading: false,
        });
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des langages", error);
    }
  }

  render() {
    var languages = "";
    if (this.state.loading) {
      languages = <p>Loading…</p>;
    } else {
      //console.log('loading ? : ', this.state.loading);
      //console.log('langages', this.state.languages);
      var languages = this.state.languages.map((item) => {
        return (
          <div key={item.id}>
            <p>{item.id}</p>
            <p>{item.name}</p>
          </div>
        );
      });
    }
    return (
      <div className="text-center mb-96">
        <h1 className="text-center text-4xl">Composant liste des langages</h1>
        {languages}
      </div>
    );
  }
}

class LanguageListById extends Component {
  state = {
    languages: [],
    loading: true,
  };

  async componentDidMount() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_URL}/api/languages`);
      console.log("requete axios langages :", res);
      if (res.data.status === 200) {
        this.setState({
          languages: res.data.languages,
          loading: false,
        });
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des langages", error);
    }
  }

  render() {
    var languages = "";
    if (this.state.loading) {
      languages = <p>Loading…</p>;
    } else {
      //console.log('loading ? : ', this.state.loading);
      console.log("langages", this.state.languages);
      var languages = this.state.languages.map((item) => {
        return (
          <div key={item.id}>
            <p>{item.id}</p>
            <p>{item.name}</p>
          </div>
        );
      });
    }
    return (
      <div>
        <h1>Langages</h1>
        {languages}
      </div>
    );
  }
}
export { LanguageForm, LanguageList, LanguageListById };

import React from "react";
import Footer from "../footer";
import ProjectForm from "../Projects";
import { LanguageForm } from "../languages";
import CategoryForm from "../categories";
import { ContactList } from "../contactForm";
import JobForm from "../job";
import { ToastContainer } from "react-toastify";
const AdminIndex = () => {
  return (
    <div>
      <h2>Tableau de bord d'administration</h2>
      <div className="flex justify-center gap-6">
        <ProjectForm />
        <JobForm />
        <div>
          <CategoryForm />
          <LanguageForm />
        </div>
      </div>
      <ContactList />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default AdminIndex;

import footerTabs from "../data/footer";

function Footer() {
  return (
    <footer>
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 2xl:py-12 lg:px-8">
        <div className="mt-10 flex justify-center space-x-10">
          {footerTabs.social.map((item) => (
            <a key={item.name} href={item.href}>
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <p className="mt-10 text-center text-base leading-5">
          &copy; 2024 Cédric Gayraud
        </p>
      </div>
    </footer>
  );
}

export default Footer;

import React, { useState, useEffect } from "react";
import { Timeline } from '@mui/lab';
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import axios from "axios";
import { FaArrowDown } from "react-icons/fa6";

const Experiences = () => {
  const [jobs, setJobs] = useState([]);
  const [projectLanguages, setProjectLanguages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEnterprise, setSelectedEnterprise] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_URL}/api/jobs`);
        //console.log("liste des jobs :", res.data);
        if (res.data.status === 200) {
          setJobs(res.data["jobs"]);
          setProjectLanguages(res.data["projectLanguages"]);
          setLoading(false);
        }

        const firstJobId = res.data["jobs"][0].id;
        setSelectedEnterprise(firstJobId);
      } catch (error) {
        console.error("Error lors de la récupération des jobs", error);
      }
    };
    fetchData();

    return () => {};
  }, []);

  const handleEnterpriseSelect = (enterpriseId) => {
    setSelectedEnterprise(enterpriseId);
    //console.log("Selected Enterprise ID:", enterpriseId);
  };

  const isCurrentEnterprise = (enterpriseId) => {
    return selectedEnterprise === enterpriseId;
  };

  let jobsHTML = "";
  let jobsTimeLine = "";
  if (loading) {
    jobsHTML = <p>Loading...</p>;
    jobsTimeLine = <p>Loading...</p>;
  } else {
    jobsTimeLine = jobs.map((job) => (
      <div key={job.id} className="w-full">
        <Timeline className="p-0">
          <TimelineItem>
            <TimelineOppositeContent
              color="text.secondary"
              className="sm:h-fit sm:w-fit w-2/5 p-0"
            >
              <p
                className={`flex items-center justify-center sm:m-auto sm:text-2xl text-lg sm:w-fit w-full sm:h-[76px] hover:text-bleuFonce rounded-3xl text-center hover:bg-bgScroll sm:px-5 px-1 py-1.5 cursor-pointer ${
                  isCurrentEnterprise(job.id) ? "text-bleuFonce rounded-3xl bg-bgScroll" : ""
                }`}
                onClick={() => handleEnterpriseSelect(job.id)}
              >
                {job.enterpriseName}
              </p>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className="w-4 h-4 bg-bleuFonce my-2" />
              <TimelineConnector className="h-4 w-[3px] bg-bleuFonce" />
            </TimelineSeparator>
            <TimelineContent className="block sm:w-fit w-2/5 mx-auto">
              <div className="flex justify-center sm:flex-row flex-col text-justify text-lg">
                <p className="w-fit text-nowrap sm:text-start sm:text-base sm:mx-0 mx-auto text-sm">
                  {job.dateStart}
                </p>
                <p className="sm:block hidden">&nbsp; - &nbsp;</p>
                <FaArrowDown className="sm:hidden block mx-auto text-bleu w-3 h-3" />
                <p className="w-fit text-nowrap sm:text-start sm:text-base sm:mx-0 mx-auto text-sm">
                  {" "}
                  {job.dateEnd}
                </p>
              </div>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </div>
    ));
    const filteredJobs = jobs.filter((job) => job.id === selectedEnterprise);

    jobsHTML = filteredJobs.map((job) => (
      <div key={job.id} className="flex justify-center w-full">
        <div className="w-full">
          <p className="text-bleuFonce text-3xl mb-3 sm:text-start text-center mt-3">
            {job.name}
          </p>
          <p className="text-bleuFonce text-base text-justify">
            {job.jobDescription}
          </p>
          <div className="flex gap-2 mt-4">
            {projectLanguages[job.id].map((projectLanguages, index) => (
              <p
                key={index}
                className="sm:text-xl text-base text-blanc uppercase bg-bleuFonce rounded-full py-2 px-4 w-fit"
              >
                {projectLanguages.language.name}
              </p>
            ))}
          </div>
        </div>
      </div>
    ));
  }

  return (
    <section id="exp" className="2xl:my-32 my-10">
      <h1 className="2xl:text-5xl text-3xl sm:text-start text-center text-bleuFonce">
        Expériences
      </h1>
      <div className="flex justify-evenly sm:flex-row flex-col sm:my-20 my-10">
        <div className="sm:w-2/5 w-full">{jobsTimeLine}</div>

        <div className="sm:w-3/5 w-full">{jobsHTML}</div>
      </div>
    </section>
  );
};

export default Experiences;

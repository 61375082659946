import { useState } from "react";
import AuthUser from "./AuthUser";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../data/toats";

export default function Login() {
    const navigate = useNavigate();
    const { http, setToken } = AuthUser();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(""); // Ajout de l'état pour le message d'erreur

    const config = {
        headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
    };

    const submitForm = (e) => {
        e.preventDefault(); // Empêche le rechargement de la page

        http.post("/login", { email: email, password: password }, config)
            .then((res) => {
                console.log("API Response:", res); // Log complet de la réponse
                if (res.data && res.data.user && res.data.access_token) {
                    const { user, access_token } = res.data;
                    console.log("Résultat :", res);
                    setToken(user, access_token);
                    console.log("User:", user);
                    console.log("Access Token:", access_token);
                    notifySuccess("Connexion réussie");
                    navigate("/admin");
                } else {
                    console.error("Invalid response from API");
                    setErrorMessage(
                        "Erreur lors de la connexion. Réponse invalide."
                    );
                }
            })
            .catch((error) => {
                console.log("erreur :", error.response);
                if (error.response && error.response.status === 401) {
                    const errorMessage = error.response.data.message;
                    if (errorMessage === "Unauthorized") {
                        setErrorMessage("Il y a un problème avec le token");
                        notifyError("Erreur token");
                    } else {
                        setErrorMessage(
                            "Votre email ou mot de passe est incorrect."
                        );
                        notifyError(
                            "Votre email ou mot de passe est incorrect."
                        );
                    }
                }
            });
    };

    return (
        <div className="row justify-content-center pt-5">
            <div className="col-sm-6">
                <div className="card p-4">
                    <h1 className="text-center mb-3">Login </h1>
                    <form onSubmit={submitForm}>
                        {" "}
                        {/* Utilisez onSubmit ici */}
                        <div className="form-group">
                            <label htmlFor="email">Email address:</label>
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Enter email"
                                onChange={(e) => setEmail(e.target.value)}
                                id="email"
                                required
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="pwd">Password:</label>
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Enter password"
                                onChange={(e) => setPassword(e.target.value)}
                                id="pwd"
                                required
                            />
                        </div>
                        {errorMessage && (
                            <p className="text-rouge">{errorMessage}</p>
                        )}{" "}
                        <button
                            type="submit" // Changez le type de bouton en "submit"
                            className="btn btn-primary mt-4"
                        >
                            Login
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

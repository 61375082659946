import React, { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axios from "axios";
import { notifySuccess, notifyError } from "../data/toats";

let defaultInput =
  "w-full rounded border-[1.5px] border-stroke border-grisBtn bg-transparent py-3 px-5 text-noir outline-none transition focus:border-bleu active:border-bleu disabled:cursor-default disabled:bg-blanc";
let defaultLabel = "mb-2.5 block text-noir";

const JobForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    jobDescription: "",
    enterpriseName: "",
    dateStart: "",
    dateEnd: "",
  });

  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const languagesResponse = await axios.get(
          `${process.env.REACT_APP_URL}/api/languages`
        );
        setLanguages(languagesResponse.data.languages);
      } catch (error) {
        console.error("Erreur lors de la récupération des données", error);
      }
    };

    fetchData();
  }, []);

  const handleInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    //console.log("formdata :", formData);
  };

  const handleSelectLanguage = (selectedOptions) => {
    const selectedLanguageIds = selectedOptions.map((option) => option.value);
    setSelectedLanguages(selectedLanguageIds);
  };

  const saveJob = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("jobDescription", formData.jobDescription);
    formDataToSend.append("enterpriseName", formData.enterpriseName);
    formDataToSend.append("dateStart", formData.dateStart);
    formDataToSend.append("dateEnd", formData.dateEnd);
    selectedLanguages.forEach((languageId) => {
      formDataToSend.append("languages_id[]", languageId);
    });
    //console.log(formDataToSend);

    const res = await axios.post(
      `${process.env.REACT_APP_URL}/api/add-job`,
      formDataToSend,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (res.data.status === 200) {
      //console.log(res.data.message);
      setFormData({
        name: "",
        jobDescription: "",
        enterpriseName: "",
        dateStart: "",
        dateEnd: "",
      });
      setSelectedLanguages([]);
      notifySuccess("Le travail a bien été ajouté");
    } else {
      notifyError("Erreur, le job n'a pas été enregistré");
      console.log("Erreur, le job n'a pas été enregistré", res.data);
    }
  };

  const languagesOptions = languages.map((language) => ({
    value: language.id,
    label: language.name,
  }));

  const animatedComponents = makeAnimated();

  return (
    <div className="rounded-sm border border-grisBtn bg-blanc shadow-default p-5 h-fit">
      <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
        <h3 className="font-medium text-noir">Formulaire de jobs</h3>
      </div>
      <form
        className="max-w-md mx-auto mt-6"
        onSubmit={saveJob}
        method="post"
        encType="multipart/form-data"
      >
        <div className="p-6.5">
          <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full xl:w-1/2">
              <label htmlFor="name" className={defaultLabel}>
                Nom du job
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className={defaultInput}
                onChange={handleInput}
                value={formData.name}
                placeholder=" "
                required
              />
            </div>
            <div className="w-full xl:w-1/2">
              <label htmlFor="enterpriseName" className={defaultLabel}>
                Nom de l'entreprise
              </label>
              <input
                type="text"
                name="enterpriseName"
                id="enterpriseName"
                className={defaultInput}
                onChange={handleInput}
                value={formData.enterpriseName}
                placeholder=" "
                required
              />
            </div>
          </div>

          <div className="relative z-0 w-full mb-5 group">
            <label htmlFor="jobDescription" className={defaultLabel}>
              Desciption
            </label>
            <textarea
              type="text"
              name="jobDescription"
              id="jobDescription"
              className={defaultInput}
              onChange={handleInput}
              value={formData.jobDescription}
              placeholder=" "
              required
            />
          </div>
          <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="relative z-0 w-full xl:w-1/2 mb-5 group">
              <label htmlFor="dateStart" className={defaultLabel}>
                Date début contrat
              </label>
              <input
                type="date"
                name="dateStart"
                id="dateStart"
                className={defaultInput}
                onChange={handleInput}
                value={formData.dateStart}
                placeholder=" "
                required
              />
            </div>
            <div className="relative z-0 w-full xl:w-1/2 mb-5 group">
              <label htmlFor="dateEnd" className={defaultLabel}>
                Date fin du contrat
              </label>
              <input
                type="date"
                name="dateEnd"
                id="dateEnd"
                className={defaultInput}
                onChange={handleInput}
                value={formData.dateEnd}
                placeholder=" "
                required
              />
            </div>
          </div>

          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-5 group">
              <div className="mt-2">
                <label htmlFor="JobLanguages" className={defaultLabel}>
                  Langages
                </label>

                <Select
                  id="JobLanguages"
                  name="languages_id"
                  closeMenuOnSelect={false}
                  onChange={handleSelectLanguage}
                  components={animatedComponents}
                  value={languagesOptions.filter((option) =>
                    selectedLanguages.includes(option.value)
                  )}
                  isMulti
                  options={languagesOptions}
                />
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="select-none rounded-lg bg-bleu py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-blanc shadow-md shadow-bleu/20 transition-all hover:shadow-lg hover:shadow-bleu/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default JobForm;

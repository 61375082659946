import React, { useState } from "react";
import { MdClose, MdOutlineScreenshotMonitor } from "react-icons/md";
import { GoDash, GoDotFill } from "react-icons/go";
import { HiOutlineServerStack } from "react-icons/hi2";
import { AiOutlineShop } from "react-icons/ai";
import FullScreen from "../data/fullScreen";
import {
  FaReact,
  FaLaravel,
  FaPhp,
  FaWordpress,
  FaHtml5,
  FaCss3Alt,
  FaGithub,
} from "react-icons/fa";
import {
  SiTailwindcss,
  SiMysql,
  SiPrestashop,
  SiPostman,
} from "react-icons/si";

function About() {
  const [show, setShow] = useState(true);
  const [isReduce, setIsReduce] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  const handleClose = () => {
    setShow(false);
  };
  const handleReduce = () => {
    setIsReduce(false);
    setIsOpen(true);
    setIsExpanded(false);
  };
  const handleOpen = () => {
    setIsReduce(true);
    setIsOpen(false);
    setIsExpanded(true);
  };
  return (
    <section id="about" className="pt-[15%]">
      <div className="2xl:mb-32 sm:mb-16 mb-6">
        <h1 className="2xl:text-6xl xl:text-4xl text-3xl sm:text-start text-center text-bleu uppercase">
          Cédric Gayraud
        </h1>
        <h2 className="2xl:text-5xl xl:text-2xl text-xl sm:text-start text-center text-bleuFonce 2xl:mt-8 sm:mt-5 mt-3">
          Développeur full-stack - Intégrateur web
        </h2>
        <p className="text-bleu 2xl:mt-8 sm:w-1/2 sm:text-start text-center sm:mt-5 mt-3">

        Étudiant en développement web et logiciel, je me spécialise dans le full-stack et la gestion de projet. J'ai une affinité particulière pour Laravel, que j'utilise pour développer des applications web performantes. Pour le front-end, je privilégie Tailwind CSS, qui me permet de créer des interfaces modernes et réactives. Côté e-commerce, PrestaShop est ma solution de référence. Toujours en quête de nouveaux défis, je mène mes projets du front-end au back-end avec efficacité et créativité.        </p>
      </div>

      {show && (
        <div className="rounded-2xl text-blanc">
          <div className="bg-grisFonce rounded-t-2xl h-10">
            {isReduce && (

              <div className="flex gap-2 items-center group w-fit pt-3">
              <div className="bg-rouge rounded-full ml-3">
                  <MdClose
                    className="cursor-pointer text-transparent group-hover:text-grisFonce font-black text-lg"
                    onClick={handleClose}
                  />
                </div>
                <div className="bg-jaune rounded-full">
                  <GoDash
                    className="cursor-pointer text-transparent group-hover:text-grisFonce font-black text-lg"
                    onClick={handleReduce}
                  />
                </div>
                <div className="cursor-pointer text-transparent group-hover:text-grisFonce font-black text-lg">
                  <GoDotFill className="bg-grisBtn rounded-full" />
                </div>
              </div>


            )}
            {isOpen && (

            <div className="flex gap-2 items-center group w-fit pt-3">
                <div className="bg-rouge rounded-full ml-3">
                  <MdClose
                    className="cursor-pointer text-transparent group-hover:text-grisFonce font-black text-lg"
                    onClick={handleClose}
                  />
                </div>
                <div className="bg-grisBtn rounded-full">
                  <GoDotFill className="cursor-pointer text-transparent group-hover:text-grisFonce font-black text-lg" />
                </div>
                <div
                  className="cursor-pointer bg-vert rounded-full"
                  onClick={handleOpen}
                >
                  <FullScreen />
                </div>
                </div>

            )}
          </div>
          <div
            className={`bg-bleuFonce rounded-b-2xl ${
              isExpanded ? "2xl:py-6" : ""
            } ${isOpen ? "xl:py-4" : ""}`}
          >
            {isExpanded && (
              <>
                <h1 className="text-center 2xl:text-5xl text-3xl pt-3">
                  Ce que je fais
                </h1>
                <div className="flex sm:justify-evenly sm:flex-row flex-col sm:py-12 py-6">
                  <div className="sm:w-1/4 w-full py-5 group hover:bg-bgSite rounded-md transition duration-200 cursor-pointer">
                    <MdOutlineScreenshotMonitor className="text-9xl block mx-auto text-bleuFonce bg-blanc rounded-full p-3 group-hover:text-bgSite group-hover:bg-bleuFonce" />
                    <p className="text-center pb-5 font-semibold text-2xl mt-3 group-hover:text-bleuFonce">
                      FRONT-END
                    </p>
                    <div className="border-b-2 border-blanc w-1/2 px-4 mx-auto group-hover:border-bleuFonce"></div>
                    <p className="text-center py-4 px-6 group-hover:text-bleuFonce">
                    Transformer des designs en interfaces interactives est ma spécialité. Avec HTML, CSS, et Tailwind css, je crée des expériences utilisateur fluides et attractives, qui captivent et retiennent l'attention. Mon objectif est simple : offrir des interfaces qui ne passent pas inaperçues.                    </p>
                  </div>
                  <div className="border h-auto"></div>
                  <div className="sm:w-1/4 w-full py-5 group hover:bg-bgSite rounded-md transition duration-200 cursor-pointer">
                    <HiOutlineServerStack className="text-9xl block mx-auto text-bleuFonce bg-blanc rounded-full p-3 group-hover:text-bgSite group-hover:bg-bleuFonce" />
                    <p className="text-center pb-5 font-semibold text-2xl mt-3 group-hover:text-bleuFonce">
                      BACK-END
                    </p>
                    <div className="border-b-2 border-blanc w-1/2 px-4 mx-auto group-hover:border-bleuFonce"></div>
                    <p className="text-center py-4 px-6 group-hover:text-bleuFonce">
                    Avec PHP et Laravel, je conçois des architectures solides et des systèmes qui fonctionnent en toute transparence. Je m'assure que chaque application que je développe soit à la fois performante et sécurisée, pour que vos utilisateurs bénéficient d'une expérience sans faille.
                    </p>
                  </div>
                  <div className="border h-auto"></div>
                  <div className="sm:w-1/4 w-full py-5 group hover:bg-bgSite rounded-md transition duration-200 cursor-pointer">
                    <AiOutlineShop className="text-9xl block mx-auto text-bleuFonce bg-blanc rounded-full p-3 group-hover:text-bgSite group-hover:bg-bleuFonce" />
                    <p className="text-center pb-5 font-semibold text-2xl mt-3 group-hover:text-bleuFonce">
                      CMS
                    </p>
                    <div className="border-b-2 border-blanc w-1/2 px-4 mx-auto group-hover:border-bleuFonce"></div>
                    <p className="text-center py-4 px-6 group-hover:text-bleuFonce">
                    Quand il s'agit de construire des sites e-commerce, PrestaShop est mon terrain de jeu. Je crée des solutions sur mesure qui permettent aux entreprises de gérer facilement leurs contenus et produits, tout en offrant une expérience utilisateur optimale et engageante.
                    </p>
                  </div>
                </div>
              </>
            )}
            {isOpen && (
              <div className="flex justify-evenly  sm:overflow-hidden overflow-auto sm:gap-0 gap-4 py-2">
                <div className="flex items-center sm:space-x-2 space-x-4">
                  <FaHtml5 className="text-7xl text-[#E34C26] transition-transform transform hover:scale-125" />
                  <FaCss3Alt className="text-7xl text-[#2965F1] transition-transform transform hover:scale-125" />
                  <FaReact className="text-7xl text-[#61dbfb] transition-transform transform hover:scale-125" />
                  <SiTailwindcss className="text-7xl text-[#06b6d4] transition-transform transform hover:scale-125" />
                </div>
                <div className="border h-auto bg-grisFonce sm:block hidden"></div>
                <div className="flex items-center sm:space-x-2 space-x-4">
                  <FaLaravel className="text-7xl text-[#fb503b] transition-transform transform hover:scale-125" />
                  <FaPhp className="text-7xl text-[#8993be] transition-transform transform hover:scale-125" />
                  <SiMysql className="text-7xl text-[#F29111] transition-transform transform hover:scale-125" />
                </div>
                <div className="border h-auto bg-grisFonce sm:block hidden"></div>
                <div className="flex items-center sm:space-x-2 space-x-4">
                  <SiPrestashop className="text-7xl text-[#25B9D7] transition-transform transform hover:scale-125" />
                  <FaWordpress className="text-7xl text-[#21759B] transition-transform transform hover:scale-125" />
                </div>
                <div className="border h-auto bg-grisFonce sm:block hidden"></div>
                <div className="flex items-center sm:space-x-2 space-x-4">
                  <FaGithub className="text-7xl text-[#e8eaea] transition-transform transform hover:scale-125" />
                  <SiPostman className="text-7xl text-[#FF6C37] transition-transform transform hover:scale-125" />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </section>
  );
}

export default About;

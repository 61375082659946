import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function AuthUser() {
    const navigate = useNavigate();

    const getToken = () => {
        const tokenString = sessionStorage.getItem("token");
        if (!tokenString) {
            return null; // Retournez null si tokenString est undefined ou null
        }
        try {
            const userToken = JSON.parse(tokenString);
            return userToken;
        } catch (e) {
            console.error("Invalid JSON in token:", e);
            return null;
        }
    };

    const getUser = () => {
        const userString = sessionStorage.getItem("user");
        if (!userString) {
            return null; // Retournez null si userString est undefined ou null
        }
        try {
            const user_detail = JSON.parse(userString);
            return user_detail;
        } catch (e) {
            console.error("Invalid JSON in user:", e);
            return null;
        }
    };

    const [token, setToken] = useState(getToken());
    const [user, setUser] = useState(getUser());

    const saveToken = (user, token) => {
        if (token && user) {
            // Vérifiez que le token et l'utilisateur existent
            sessionStorage.setItem("token", JSON.stringify(token));
            sessionStorage.setItem("user", JSON.stringify(user));

            setToken(token);
            setUser(user);
            navigate("/");
        } else {
            console.error("Cannot save token or user as they are undefined");
        }
    };

    const logout = () => {
        sessionStorage.clear();
        navigate("/login");
    };

    //  Local version
    //   const http = axios.create({
    //     baseURL: "http://localhost:8000/api",
    //     headers: {
    //       "Content-type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   });

    const http = axios.create({
        baseURL: "https://api.cedricgayraud.com/api",
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return {
        setToken: saveToken,
        token,
        user,
        getToken,
        http,
        logout,
    };
}

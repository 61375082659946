import React, { useState } from "react";
import axios from "axios";
import { notifyError, notifySuccess } from "../data/toats";

function CategoryForm() {
    const [name, setName] = useState("");

    const saveCategory = async (e) => {
        e.preventDefault();

        const res = await axios.post(
            `${process.env.REACT_APP_URL}/api/add-category`,
            name,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );

        if (res.data.status === 200) {
            console.log(res.data.message);
            setName(""); // Réinitialiser le champ name après la soumission réussie
            notifySuccess("La catégorie a bien été ajoutée");
        }
        // if (error.response && error.response.status === 422) {
        //     notifyError("La catégorie n'a pas été ajoutée");
        //     console.log(
        //         "La catégorie n'a pas été ajoutée",
        //         error.response.data
        //     );
        // }
    };

    return (
        <div className="rounded-sm border border-grisBtn bg-blanc shadow-default p-5 h-fit mb-10">
            <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                <h3 className="font-medium text-noir">
                    Formulaire de catégorie
                </h3>
            </div>

            <form onSubmit={saveCategory}>
                <div className="w-full mt-2">
                    <label htmlFor="name" className="mb-2.5 block text-noir">
                        Nom de la catégorie
                    </label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full rounded border-[1.5px] border-stroke border-grisBtn bg-transparent py-3 px-5 text-noir outline-none transition focus:border-bleu active:border-bleu disabled:cursor-default disabled:bg-blanc"
                        required
                    />

                    <button
                        type="submit"
                        className="select-none rounded-lg bg-bleu mt-2 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-blanc shadow-md shadow-bleu/20 transition-all hover:shadow-lg hover:shadow-bleu/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
}

export default CategoryForm;

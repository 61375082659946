/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { notifyError, notifySuccess } from "../data/toats";

const ContactForm = () => {
  let [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    enterpriseName: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({ email: "" });
  const [requiredTypePerson, setRequiredTypePerson] = useState(false);
  const [requiredTypeEnterprise, setRequiredTypeEnterprise] = useState(false);

  const [personType, setPersonType] = useState("selectPersonType");
  const [particulierContent, setParticulierContent] = useState(false);
  const [entrepriseContent, setEntrepriseContent] = useState(false);
  //const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const navigate = useNavigate();
  function onChange(value) {
    console.log("Recaptcha validé:");
  }
  useEffect(() => {
    if (personType === "personne") {
      setParticulierContent(true);
      setRequiredTypePerson(personType === "personne");
    } else {
      setParticulierContent(false);
    }
    if (personType === "entreprise") {
      setEntrepriseContent(true);
      setRequiredTypeEnterprise(personType === "entreprise");
    } else {
      setEntrepriseContent(false);
    }
  }, [personType]);

  const handleOnChange = (e) => {
    setPersonType(e.target.value);
  };

  const handleInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };

  // const closePopUp = (e) => {
  //   setShowSuccessPopup(false);
  // };

  const SaveForm = async (e) => {
    e.preventDefault();

    let newErrors = {};
    if (requiredTypePerson && !formData.firstname) {
      newErrors.firstname = "Ce champ est requis.";
    }
    if (requiredTypePerson && !formData.lastname) {
      newErrors.lastname = "Ce champ est requis.";
    }
    if (requiredTypeEnterprise && !formData.enterpriseName) {
      newErrors.enterpriseName = "Ce champ est requis.";
    }
    if (!formData.email) {
      newErrors.email = "Ce champ est requis.";
    }
    if (!formData.subject) {
      newErrors.subject = "Ce champ est requis.";
    }
    if (!formData.message) {
      newErrors.message = "Ce champ est requis.";
    }
    setErrors(newErrors);
    // Si erreur/s stop processus d'envoi du formulaire
    if (Object.keys(newErrors).length > 0) {
      return;
    }

    const res = await axios.post(`${process.env.REACT_APP_URL}/api/send-mail`, {
      ...formData,
    });

    if (res.status === 200) {
      console.log("Message :", res.data.message);
      setFormData({
        firstname: "",
        lastname: "",
        phone: "",
        enterpriseName: "",
        email: "",
        subject: "",
        message: "",
      });
      navigate("/");
      notifySuccess("Votre email a bien été envoyé");
      // setShowSuccessPopup(true);
    } else {
      notifyError("Le message n'a pas été envoyé");
      //console.log("Le message n'a pas été envoyé");
    }
  };

  return (
    <section id="contact" className="bg-gray-100">
      <div className="mx-auto 2xl:max-w-5xl py-16 sm:py-24 lg:px-8">
        {/* Contact form */}
        <div className="px-6 py-10 sm:px-10 lg:col-span-2 xl:p-12">
          <h3 className="text-4xl text-center font-medium text-bleuFonce">
            Contact
          </h3>
          <form
            onSubmit={SaveForm}
            method="POST"
            className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            <div className="sm:col-span-2">
              <label
                htmlFor="personType"
                className="block text-lg font-medium text-bleuFonce"
              >
                Vous êtes ?
              </label>
              <div className="mt-1">
                <select
                  type="text"
                  name="personType"
                  id="personType"
                  value={personType}
                  onChange={handleOnChange}
                  autoComplete="given-name"
                  className="block w-full rounded-md border border-grisFonce sm:px-4 px-2 py-3 text-bleuFonce shadow-sm active:border-bleuFonce focus:outline-none focus:border-bleuFonce focus:border-2"
                >
                  <option value="selectPersonType" defaultValue="">
                    Un particulier ou une entreprise ?
                  </option>
                  <option value="personne" defaultValue="">
                    Un particulier
                  </option>
                  <option value="entreprise" defaultValue="">
                    Une entreprise
                  </option>
                </select>
              </div>
            </div>

            {particulierContent && (
              <div className="sm:col-span-2 sm:flex sm:gap-x-8 sm:flex-row block">
                <div className="sm:w-1/2 w-full">
                  <label
                    htmlFor="lastname"
                    className="block text-lg font-medium text-bleuFonce"
                  >
                    Nom
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="lastname"
                      id="lastname"
                      value={formData.lastname}
                      onChange={handleInput}
                      autoComplete="family-name"
                      className={`block w-full rounded-md border ${
                        errors.firstname ? "border-rouge" : "border-grisFonce"
                      } px-4 py-3 text-bleuFonce shadow-sm focus:outline-none focus:border-bleuFonce focus:border-2`}
                    />
                    {errors.lastname && (
                      <p className="text-rouge ml-1">{errors.lastname}</p>
                    )}
                  </div>
                </div>
                <div className="sm:w-1/2 w-full sm:pt-0 pt-6">
                  <label
                    htmlFor="firstname"
                    className="block text-lg font-medium text-bleuFonce"
                  >
                    Prénom
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="firstname"
                      id="firstname"
                      value={formData.firstname}
                      onChange={handleInput}
                      autoComplete="given-name"
                      className={`block w-full rounded-md border ${
                        errors.firstname ? "border-rouge" : "border-grisFonce"
                      }  px-4 py-3 text-bleuFonce shadow-sm active:border-bleuFonce focus:outline-none focus:border-bleuFonce focus:border-2`}
                    />
                    {errors.firstname && (
                      <p className="text-rouge ml-1">{errors.firstname}</p>
                    )}
                  </div>
                </div>
              </div>
            )}

            {entrepriseContent && (
              <div className="sm:col-span-2">
                <div className="sm:flex justify-between gap-x-8 mb-6 block">
                  <div className="sm:w-1/2 w-full">
                    <div className="flex justify-between">
                      <label
                        htmlFor="lastname"
                        className="block text-lg font-medium text-bleuFonce"
                      >
                        Nom
                      </label>
                      <span
                        id="phone-optional"
                        className="text-sm text-grisFonce"
                      >
                        Optionnel
                      </span>
                    </div>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="lastname"
                        id="lastname"
                        value={formData.lastname}
                        onChange={handleInput}
                        autoComplete="family-name"
                        className="block w-full rounded-md border border-grisFonce px-4 py-3 text-bleuFonce shadow-sm active:border-bleuFonce focus:outline-none focus:border-bleuFonce focus:border-2"
                      />
                    </div>
                  </div>

                  <div className="sm:w-1/2 w-full sm:pt-0 pt-6">
                    <div className="flex justify-between">
                      <label
                        htmlFor="firstname"
                        className="block text-lg font-medium text-bleuFonce"
                      >
                        Prénom
                      </label>
                      <span
                        id="phone-optional"
                        className="text-sm text-grisFonce"
                      >
                        Optionnel
                      </span>
                    </div>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="firstname"
                        id="firstname"
                        value={formData.firstname}
                        onChange={handleInput}
                        autoComplete="given-name"
                        className="block w-full rounded-md border border-grisFonce px-4 py-3 text-bleuFonce shadow-sm active:border-bleuFonce focus:outline-none focus:border-bleuFonce focus:border-2"
                      />
                    </div>
                  </div>
                </div>
                <label
                  htmlFor="subject"
                  className="block text-lg font-medium text-bleuFonce"
                >
                  Nom de l'entreprise
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="enterpriseName"
                    id="enterpriseName"
                    value={formData.enterpriseName}
                    onChange={handleInput}
                    className={`block w-full rounded-md border ${
                      errors.enterpriseName
                        ? "border-rouge"
                        : "border-grisFonce"
                    } px-4 py-3 text-bleuFonce shadow-sm active:border-bleuFonce focus:outline-none focus:border-bleuFonce focus:border-2`}
                  />
                  {errors.enterpriseName && (
                    <p className="text-rouge ml-1">{errors.enterpriseName}</p>
                  )}
                </div>
              </div>
            )}

            <div>
              <label
                htmlFor="email"
                className="block text-lg font-medium text-bleuFonce"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInput}
                  autoComplete="email"
                  errormessage="test error"
                  className={`block w-full rounded-md border ${
                    errors.email ? "border-rouge" : "border-grisFonce"
                  } px-4 py-3 text-bleuFonce shadow-sm  focus:outline-none focus:border-bleuFonce focus:border-2`}
                />
                {errors.email && (
                  <p className="text-rouge ml-1">{errors.email}</p>
                )}
              </div>
            </div>

            <div>
              <div className="flex justify-between">
                <label
                  htmlFor="phone"
                  className="block text-lg font-medium text-bleuFonce"
                >
                  Téléphone
                </label>
                <span id="phone-optional" className="text-sm text-grisFonce">
                  Optionnel
                </span>
              </div>
              <div className="mt-1">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  autoComplete="tel"
                  value={formData.phone}
                  onChange={handleInput}
                  className="block w-full rounded-md border border-grisFonce px-4 py-3 text-bleuFonce shadow-sm active:border-bleuFonce focus:outline-none focus:border-bleuFonce focus:border-2"
                  aria-describedby="phone-optional"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="subject"
                className="block text-lg font-medium text-bleuFonce"
              >
                Sujet
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  value={formData.subject}
                  onChange={handleInput}
                  className={`block w-full rounded-md border ${
                    errors.subject ? "border-rouge" : "border-grisFonce"
                  } px-4 py-3 text-bleuFonce shadow-sm  focus:outline-none focus:border-bleuFonce focus:border-2`}
                />
                {errors.subject && (
                  <p className="text-rouge ml-1">{errors.subject}</p>
                )}
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="flex justify-between">
                <label
                  htmlFor="message"
                  className="block text-lg font-medium text-bleuFonce"
                >
                  Message
                </label>
                <span id="message-max" className="text-sm">
                  Max. 500 caractères
                </span>
              </div>
              <div className="mt-1">
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  value={formData.message}
                  onChange={handleInput}
                  className={`block w-full rounded-md border ${
                    errors.email ? "border-rouge" : "border-grisFonce"
                  } px-4 py-3 text-bleuFonce shadow-sm active:border-bleuFonce focus:outline-none focus:border-bleuFonce focus:border-2`}
                  aria-describedby="message-max"
                  maxLength="500"
                />
                {errors.message && (
                  <p className="text-rouge ml-1">{errors.message}</p>
                )}
              </div>
            </div>
            <ReCAPTCHA
              sitekey="6LcRgsIpAAAAAPDMu97Jj5y1RcMiQCkJrS-26RXu"
              onChange={onChange}
            />
            <div className="sm:col-span-2 sm:flex sm:justify-end">
              <button
                type="submit"
                className="select-none rounded-lg bg-bleu py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-blanc shadow-md shadow-bleu/20 transition-all hover:shadow-lg hover:shadow-bleu/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              >
                Envoyer
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* {showSuccessPopup && (
        <div className="fixed inset-0 p-4 z-50 flex flex-wrap justify-center items-center w-full h-full before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
          <div className="w-full max-w-lg bg-blanc shadow-lg rounded-md pt-6 relative">
            <div className="my-6 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-16 shrink-0 fill-vert inline"
                viewBox="0 0 512 512"
              >
                <path
                  d="M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z"
                  data-original="#000000"
                />
                <path
                  d="M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z"
                  data-original="#000000"
                />
              </svg>
              <h4 className="text-2xl text-vert font-semibold mt-6">
                Message envoyé !
              </h4>
            </div>
            <button
              type="button"
              className="px-6 py-2.5 min-w-[150px] w-full text-blanc text-white text-sm font-semibold border-none outline-none bg-vert hover:bg-[#019d3d]"
              onClick={closePopUp}
            >
              OK
            </button>
          </div>
        </div>
      )} */}
    </section>
  );
};

const ContactList = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openMail, setOpenMail] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URL}/api/contacts`
        );
        if (res.data.status === 200) {
          //console.log("contacts : ", res.data.contacts);
          setContacts(res.data.contacts);
          setLoading(false);
        }
      } catch (error) {
        console.log("Impossible de récupérer les emails de contact", error);
      }
    };
    fetchData();
  }, []);

  const handleRead = async (contactId) => {
    try {
      const contactToUpdate = contacts.find(
        (contact) => contact.id === contactId
      );

      if (contactToUpdate.status === 0) {
        await axios.put(
          `${process.env.REACT_APP_URL}/api/contacts/${contactId}`
        );

        // Mise à jour localement du statut du contact
        setContacts((prevContacts) =>
          prevContacts.map((contact) =>
            contact.id === contactId ? { ...contact, status: 1 } : contact
          )
        );

        setOpenMail(!openMail);
      } else if (contactToUpdate.status === 1) {
        await axios.put(
          `${process.env.REACT_APP_URL}/api/contacts/${contactId}`
        );

        // Mise à jour localement du statut du contact
        setContacts((prevContacts) =>
          prevContacts.map((contact) =>
            contact.id === contactId ? { ...contact, status: 2 } : contact
          )
        );
      } else {
        setOpenMail(!openMail);
        //console.log("Le statut du contact est déjà lu ou répondu.");
      }
    } catch (error) {
      console.error(
        "Une erreur s'est produite lors de la mise à jour du statut :",
        error
      );
    }
  };

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Les mois commencent à 0
    const year = dateObject.getFullYear();
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();

    // Ajouter un zéro initial si le jour ou le mois est inférieur à 10
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedDay}-${formattedMonth}-${year} ${formattedHours}:${formattedMinutes}`;
  };

  let contactsTable = "";
  if (loading) {
    contactsTable = <p>Loading…</p>;
  } else {
    contactsTable = (
      <div>
        <table className="w-4/5 mx-auto mt-5 text-sm text-left rtl:text-right border border-gray">
          <thead className="text-base text-bleuFonce bg-bgSite dark:bg-gray-700 rounded-lg">
            <tr className="rounded-lg">
              <th scope="col" className="px-6 py-3 text-center">
                Sujet
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Type
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Nom/Prénom
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Entreprise
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Email
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Téléphone
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Date réception
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Statut
              </th>
            </tr>
          </thead>
          <tbody className="font-medium">
            {contacts.map((contact) => (
              <tr
                key={contact.id}
                onClick={() => {
                  setSelectedContact(contact);
                  setOpenMail(true);
                }}
                className={`${
                  contact.status === 0
                    ? "bg-blanc hover:bg-gray2"
                    : "bg-bgScroll"
                } border-b border-gray cursor-pointer`}
              >
                <td className="px-6 py-4  whitespace-nowrap dark:text-white">
                  <p className="px-3 py-2 rounded-3xl w-fit mx-auto bg-orange2 text-orange1">
                    {contact.subject}
                  </p>
                </td>
                <td className="px-6 py-4">
                  <p
                    className={`px-3 py-2 rounded-3xl w-fit mx-auto ${
                      contact.enterpriseName
                        ? "bg-violet2 text-violet1"
                        : "bg-blou2 text-blou1"
                    }`}
                  >
                    {contact.enterpriseName ? "Entreprise" : "Particulier"}
                  </p>
                </td>
                <td className="px-6 py-4">
                  <p className="w-fit mx-auto">
                    {contact.firstname && contact.lastname
                      ? contact.firstname + " " + contact.lastname
                      : ""}
                  </p>
                </td>

                <td className="px-6 py-4">
                  {contact.enterpriseName ? contact.enterpriseName : ""}
                </td>
                <td className="px-6 py-4">
                  <p className="px-3 py-2 rounded-3xl bg-zinc2 text-zinc1 w-fit mx-auto">
                    {contact.email}
                  </p>
                </td>
                <td className="px-6 py-4">
                  <p className="w-fit mx-auto">{contact.phone}</p>
                </td>
                <td className="px-6 py-4">
                  {" "}
                  <p className="w-fit mx-auto">
                    {formatDate(contact.created_at)}
                  </p>
                </td>
                <td className="px-6 py-4 text-center">
                  {contact.status === 1 ? (
                    <select
                      value={contact.status}
                      className="w-fit mx-auto px-3 py-2 rounded-xl bg-rose2 text-rose1 hover:bg-rose3"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e) => {
                        const newStatus = e.target.value;
                        if (newStatus === "2") {
                          // Si le nouveau statut est "Répondu"
                          handleRead(contact.id); // Appeler handleRead avec l'ID du contact
                        }
                      }}
                    >
                      <option value={1}>Lu</option>
                      <option value={2}>Répondu</option>
                    </select>
                  ) : (
                    <p
                      className={`w-fit mx-auto cursor-pointer px-3 py-2 rounded-xl ${
                        contact.status === 0
                          ? "bg-green2 text-green1"
                          : contact.status === 1
                          ? "bg-rose2 text-rose1 hover:bg-rose3"
                          : "bg-purple2 text-purple1 hover:bg-blue-600"
                      }`}
                    >
                      {contact.status === 0
                        ? "Nouveau"
                        : contact.status === 1
                        ? "Lu"
                        : "Répondu"}{" "}
                      {/* Affichage du statut correspondant */}
                    </p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {openMail && (
          <div className="fixed inset-0 p-4 z-50 flex flex-wrap justify-center items-center w-full h-full before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
            <div className="w-fit bg-blanc shadow-lg rounded-md p-6 relative">
              <div className="flex gap-6">
                <p>Message de : {selectedContact.email}</p>
                <p>Reçu le : {formatDate(selectedContact.created_at)}</p>
              </div>
              <p className="py-3">Message : {selectedContact.message}</p>
              <button
                type="button"
                className="px-6 py-2.5 min-w-[150px] w-full text-sm font-semibold border-none outline-none bg-green3 text-green1 hover:bg-green2"
                onClick={() => handleRead(selectedContact.id)}
              >
                OK
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return <div>{contactsTable}</div>;
};

export { ContactForm, ContactList };

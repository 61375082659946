import Footer from "./footer";
import { ProjectList } from "./Projects";
import { ContactForm } from "./contactForm";
import About from "./about";
import Experiences from "./experiences";
import { ToastContainer } from "react-toastify";
function FrontIndex() {
  //console.log("app url : ", process.env.REACT_APP_URL);
  return (
    <div className="3xl:px-80 2xl:px-32 xl:px-24 lg:px-16 md:px-12 sm:px-10 px-6">
      <About />
      <Experiences />
      <ProjectList />
      <ContactForm />
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default FrontIndex;

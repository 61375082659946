/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axios from "axios";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { notifySuccess, notifyError } from "../data/toats";
let defaultInput =
  "w-full rounded border-[1.5px] border-stroke border-grisBtn bg-transparent py-3 px-5 text-noir outline-none transition focus:border-bleu active:border-bleu disabled:cursor-default disabled:bg-blanc";
let defaultLabel = "mb-2.5 block text-noir";

const ProjectForm = () => {
  const [formData, setFormData] = useState({
    site_name: "",
    site_desc: "",
    site_url: "",
    project_date: "",
    collaborators: "",
    files: [],
    id_category: "",
  });
  const [categories, setCategories] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesResponse = await axios.get(
          `${process.env.REACT_APP_URL}/api/categories`
        );
        setCategories(categoriesResponse.data.categories);

        const languagesResponse = await axios.get(
          `${process.env.REACT_APP_URL}/api/languages`
        );
        setLanguages(languagesResponse.data.languages);
      } catch (error) {
        console.error("Erreur lors de la récupération des données", error);
      }
    };

    fetchData();
  }, []);

  const handleInput = (e) => {
    if (e.target.name === "files") {
      setFormData({
        ...formData,
        files: [...formData.files, ...e.target.files],
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
    //console.log("formdata :", formData);
  };

  const handleSelectCategory = (selectedOption) => {
    setFormData({
      ...formData,
      id_category: selectedOption.value,
    });
  };

  const handleSelectLanguage = (selectedOptions) => {
    const selectedLanguageIds = selectedOptions.map((option) => option.value);
    setSelectedLanguages(selectedLanguageIds);
  };

  const saveProject = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("site_name", formData.site_name);
    formDataToSend.append("site_desc", formData.site_desc);
    formDataToSend.append("site_url", formData.site_url);
    formDataToSend.append("project_date", formData.project_date);
    formDataToSend.append("collaborators", formData.collaborators);
    formData.files.forEach((files, index) => {
      formDataToSend.append(`files[${index}]`, files); // Ajoutez chaque fichier dans le FormData avec une clé unique
    });
    formDataToSend.append("id_category", formData.id_category);
    selectedLanguages.forEach((languageId) => {
      formDataToSend.append("languages_id[]", languageId);
    });
    //console.log("Data to send", formDataToSend);

    const res = await axios.post(
      `${process.env.REACT_APP_URL}/api/add-project`,
      formDataToSend,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (res.data.status === 200) {
      //console.log(res.data.message);
      setFormData({
        site_name: "",
        site_desc: "",
        site_url: "",
        project_date: "",
        collaborators: "",
        files: [],
        id_category: "",
      });
      setSelectedLanguages([]);
      notifySuccess("Le projet à bien été ajouté");
    } else {
      notifyError("Erreur, le projet n'a pas été enregistré");
      console.log(
        "Erreur, le projet n'a pas été enregistré, le fichier est peut-être trop lourd",
        res.data
      );
    }
  };

  const categoryOptions = categories.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  const languagesOptions = languages.map((language) => ({
    value: language.id,
    label: language.name,
  }));

  const animatedComponents = makeAnimated();

  return (
    <div className="rounded-sm border border-grisBtn bg-blanc shadow-default p-5">
      <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
        <h3 className="font-medium text-noir">Formulaire de projet</h3>
      </div>
      <form
        className="max-w-md mx-auto mt-6"
        onSubmit={saveProject}
        method="post"
        encType="multipart/form-data"
      >
        <div className="p-6.5">
          <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full xl:w-1/2">
              <label htmlFor="site_name" className={defaultLabel}>
                Nom du site <span className="text-meta-1">*</span>
              </label>
              <input
                type="text"
                name="site_name"
                id="site_name"
                className={defaultInput}
                onChange={handleInput}
                value={formData.site_name}
                placeholder=" "
                required
              />
            </div>

            <div className="w-full xl:w-1/2">
              <label htmlFor="site_url" className={defaultLabel}>
                URL du site
              </label>
              <input
                type="text"
                name="site_url"
                id="site_url"
                className={defaultInput}
                onChange={handleInput}
                value={formData.site_url}
                placeholder=" "
              />
            </div>
          </div>

          <div className="relative z-0 w-full mb-5 group">
            <label htmlFor="site_desc" className={defaultLabel}>
              desciption
            </label>
            <textarea
              type="text"
              name="site_desc"
              id="site_desc"
              className={defaultInput}
              onChange={handleInput}
              value={formData.site_desc}
              placeholder=" "
              required
            />
          </div>
          <div className="mb-4.5">
            <label htmlFor="project_date" className={defaultLabel}>
              Date du projet
            </label>
            <input
              type="date"
              name="project_date"
              id="project_date"
              className={defaultInput}
              onChange={handleInput}
              value={formData.project_date}
              placeholder=" "
              required
            />
          </div>
          <div className="relative z-0 w-full mb-5 group">
            <label htmlFor="collaborators" className={defaultLabel}>
              Collaborateurs
            </label>
            <input
              type="text"
              name="collaborators"
              id="collaborators"
              className={defaultInput}
              onChange={handleInput}
              value={formData.collaborators}
              placeholder=" "
              required
            />
          </div>
          <div className="relative z-0 w-full mb-5 group">
            <label
              htmlFor="file_input"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-blanc"
            >
              Ajouter un fichier
            </label>
            <input
              type="file"
              name="files"
              id="file_input"
              className="w-full cursor-pointer rounded-lg border-[1.5px] border-stroke bg-transparent outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-whiter file:py-3 file:px-5 file:hover:bg-primary file:hover:bg-opacity-10 focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:file:border-form-strokedark dark:file:bg-white/30 dark:file:text-white dark:focus:border-primary"
              onChange={handleInput}
              placeholder=" "
              multiple
              required
            />
            <label
              htmlFor="file_input"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-blanc"
            >
              Ajouter un fichier
            </label>
            {formData.files.length > 0 && (
              <div>
                <p>Fichiers téléchargés :</p>
                <ul>
                  {formData.files.map((file, index) => (
                    <li key={index}>{file.name}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-5 group">
              <div className="mt-2">
                <label htmlFor="category" className={defaultLabel}>
                  Catégories
                </label>
                <Select
                  id="category"
                  name="id_category"
                  closeMenuOnSelect={true}
                  onChange={handleSelectCategory}
                  components={animatedComponents}
                  value={categoryOptions.find(
                    (option) => option.value === formData.id_category
                  )}
                  defaultValue=""
                  isMulti={false}
                  options={categoryOptions}
                />
              </div>
            </div>

            <div className="relative z-0 w-full mb-5 group">
              <div className="mt-2">
                <label htmlFor="Projectlanguages" className={defaultLabel}>
                  Langages
                </label>

                <Select
                  id="Projectlanguages"
                  name="languages_id"
                  closeMenuOnSelect={false}
                  onChange={handleSelectLanguage}
                  components={animatedComponents}
                  value={languagesOptions.filter((option) =>
                    selectedLanguages.includes(option.value)
                  )}
                  isMulti
                  options={languagesOptions}
                />
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="select-none rounded-lg bg-bleu py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-blanc shadow-md shadow-bleu/20 transition-all hover:shadow-lg hover:shadow-bleu/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProjectForm;

const ProjectList = () => {
  const [projects, setProjects] = useState([]);
  const [projectLanguages, setProjectLanguages] = useState([]);
  const [projectImages, setProjectImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedProjectId, setExpandedProjectId] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URL}/api/projects`
        );
        if (res.data.status === 200) {
          setProjects(res.data.projects);
          setProjectLanguages(res.data.projectLanguages);
          setProjectImages(res.data.projectImages);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    const closeExpandedPhoto = (event) => {
      if (event.key === "Escape") {
        setExpandedProjectId(null);
      }
    };

    document.addEventListener("keydown", closeExpandedPhoto);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("keydown", closeExpandedPhoto);
    };
  }, []);

  useEffect(() => {
    //console.log("ScreenWidth:", screenWidth);
  }, [screenWidth]);

  const handleProjetExpand = (projectId) => {
    setExpandedProjectId(projectId);
  };

  let projectsHtml = "";
  if (loading) {
    projectsHtml = <p>Loading…</p>;
  } else {
    projectsHtml = projects.map((project) => (
      <div
        key={project.id}
        className={`relative 3xl:w-[520px] 3xl:h-[520px] 2xl:w-[410px] 2xl:h-[410px] xl:w-[340px] xl:h-[340px] lg:w-[280px] lg:h-[280px] w-screen h-96 bg-center object-cover group`}
      >
        {projectImages[project.id] && projectImages[project.id].length > 0 && (
          <div
            style={{
              backgroundImage: `url(${
                process.env.REACT_APP_URL
              }/storage/images/project/${project.id}/${
                projectImages[project.id][0].image_name
              })`,
            }}
            className="absolute top-0 left-0 right-0 bottom-0 bg-cover z-10"
          ></div>
        )}
        <div className="absolute bottom-0 left-0 right-0 h-full bg-noir opacity-0 group-hover:opacity-100 bg-opacity-70 py-3 z-10">
          <div className="block my-auto absolute top-1/3 w-full">
            {project.site_url ? (
              <a
                href={project.site_url}
                className="text-blanc text-4xl font-semibold mt-1 hover:underline block text-center"
              >
                {project.site_name}
              </a>
            ) : (
              <p className="text-blanc text-4xl font-semibold mt-1 text-center">
                {project.site_name}
              </p>
            )}

            <button
              onClick={() => handleProjetExpand(project.id)}
              className="block mx-auto mt-10 before:ease text-lg relative h-12 w-40 overflow-hidden border-2 border-blanc text-blanc shadow-2xl transition-all before:absolute before:top-1/2 before:h-0 before:w-64 before:origin-center before:-translate-x-20 before:rotate-45 before:bg-blanc before:duration-500 hover:text-noir hover:text-opacity-90 hover:shadow-blanc hover:before:h-64 hover:before:-translate-y-32"
            >
              <span className="relative z-10">Voir le projet</span>
            </button>
          </div>
        </div>
        {expandedProjectId === project.id && (
          <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-noir bg-opacity-70 z-50">
            <div className="flex w-4/5">
              <div className="w-1/2">
                <ImageGallery
                  items={projectImages[project.id].map((image) => ({
                    original: `${process.env.REACT_APP_URL}/storage/images/project/${project.id}/${image.image_name}`,
                    thumbnail: `${process.env.REACT_APP_URL}/storage/images/project/${project.id}/${image.image_name}`,
                  }))}
                  showPlayButton={false}
                  showFullscreenButton={false}
                  thumbnailPosition="left"
                />
              </div>

              <div className="w-1/2 text-blanc 2xl:px-12">
                <h3 className="2xl:text-4xl">{project.site_name}</h3>
                <p className="2xl:text-xl py-5">
                  {project.site_desc}
                  {project.collaborators && (
                    <p>
                      Fait en collaboration avec{" "}
                      <a
                        href="https://github.com/FabPinel"
                        className="font-semibold"
                      >
                        {project.collaborators}
                      </a>
                    </p>
                  )}
                </p>
                {/* <p>{project.collaborators}</p> */}
                <div className="flex gap-2">
                  {projectLanguages[project.id].map(
                    (projectLanguage, index) => (
                      <div key={index}>
                        <p className="text-bleu text-xl font-semibold bg-blanc py-2 px-3 rounded-full uppercase">
                          {projectLanguage.language.name}
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    ));
  }

  return (
    <section id="projects" className="mx-auto">
      <h1 className="text-center text-4xl text-bleu py-5">Projets</h1>
      <div className="flex gap-4 flex-wrap">{projectsHtml}</div>
    </section>
  );
};

export { ProjectForm, ProjectList };

import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="bg-bgSite h-screen p-48">
      <div className="text-center">
        <p className="text-7xl text-bleuFonce my-4">Page introuvable.</p>
        <p className="text-3xl text-bleu my-4">
          La page que vous avez demandé n'existe pas
        </p>
        <p>
          <Link
            to="/"
            className="select-none rounded-lg mt-4 bg-bleu py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-blanc shadow-md shadow-bleu/20 transition-all hover:shadow-lg hover:shadow-bleu/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          >
            Accueil
          </Link>
        </p>
      </div>
    </div>
  );
}

export default NotFound;
